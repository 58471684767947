import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import rgbHex from 'rgb-hex';
import VueGtm from '@gtm-support/vue2-gtm';

if (process.env.VUE_APP_CLIENT == 'cms') {
  Vue.use( VueGtm, {
    id: "GTM-WLHFXVV",
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    enabled: true,
    loadScript: true,
  });
}

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    getMedia(str) {
      if(process.env.VUE_APP_CLIENT == 'kiosk') {
        return str
      }
      else {
        // if 'storage/uploads' does not exist in the url path, add it.
        if(str.indexOf('storage/uploads') < 0) {
          str = 'storage/uploads' + str
        }
        return `${ process.env.VUE_APP_CMS_BASE_URL }${ str }`
      }
    },
    setGradient( colorTheme, angle = 15 ){
      return `linear-gradient(${angle}deg, ${colorTheme.color2}, ${colorTheme.color1})`
    },
  }
})

const renderApp = async function(){
  var globalUrl = `${process.env.VUE_APP_CMS_BASE_URL}api/singletons/get/global?populate=1`;
  if (process.env.VUE_APP_CLIENT == 'kiosk') {
    globalUrl = '/offline-content/global.js';
  }

  const globals = await axios.get( globalUrl,
    {
      timeout: 5000
    }
  )

  const themeColors = {
    primary: rgbHex(globals.data.color_theme.color2),
    primaryLighter: rgbHex(globals.data.color_theme.color1),
    primaryText: rgbHex(globals.data.color_theme.textcolor),
    secondary: rgbHex(globals.data.secondaryColor.color2),
    secondaryLighter: rgbHex(globals.data.secondaryColor.color1),
    secondaryText: rgbHex(globals.data.secondaryColor.textcolor)
  };

  vuetify.framework.theme.themes.light = themeColors;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

renderApp();


